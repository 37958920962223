.container {
  display: flex;
  width: 100%;
  margin: 0 auto;
  font-family: "Montserrat";
}

.left {
  width: 55%;
  height: 100vh;
  position: sticky;
  top: 0;
  background: url("/images/gif/homegif.gif") lightgray 50% / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.right {
  width: calc(45% - 58px);
  display: flex;
  flex-direction: column;
  gap: 840px;
  padding: 0 58px;
  margin-bottom: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 840px; /* Space between blocks */
  padding-top: 463px;
}

.text-p {
  text-align: center;
  align-content: center;
  align-items: center;
  font-size: 24px;
  color: #333;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    width: 100%;
    display: none;
  }

  .left {
    width: 100%;
    height: 50vh;
    position: relative;
  }

  .right {
    width: 100%;
    gap: 40px; /* Reduce gap for smaller screens */
    margin-bottom: 0;
    margin-top: 10px;
    padding: 0 20px; /* Adjust padding for smaller screens */
  }

  .content {
    gap: 40px; /* Reduce gap for smaller screens */
    padding-top: 0; /* Remove padding-top */
  }

  .text-p {
    font-size: 18px; /* Adjust font size for smaller screens */
  }
}
