.card {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background: rgba(40, 100, 151, 0.2);
  backdrop-filter: blur(25px);
  display: flex;
  font-size: 24px;
  display: flex;
  flex-direction: column;
}

.container {
  min-height: 100vh;
  position: relative;
  display: none;
}

.imageWrapper {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: hidden;
}

.imageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.cardsWrapper {
  position: relative;
  z-index: 1;

  padding: 20px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 249px;
}

.textTitle {
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
}

.textContent {
  color: #fffbfe;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    display: block;
  }
}
